// src/theme.ts
import { createTheme, rem } from '@mantine/core';

export const theme = createTheme({
  fontFamily:  'Montserrat, sans-serif',
  headings: {
    // properties for all headings
    fontWeight: '400',
    fontFamily: 'Montserrat, sans-serif',

    // properties for individual headings, all of them are optional
    sizes: {
      h1: {
        fontWeight: '900',
        fontSize: rem(54),
        lineHeight: '1.05',
        letterSpacing: '500'
      },
      h2: { fontSize: rem(40), lineHeight: '1.5' },
      h3: { fontSize: rem(28), lineHeight: '1.5' },
      h6: { fontWeight: '900' },
    },
  },
  // ... other theme override properties
});